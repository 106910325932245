<template>
  <div class="row justify-content-center align-items-md-center">
    <div
      class="col-md-4"
      v-for="(item, index) in lista_finan_pedido_recebimento"
      :key="index"
    >
      <div class="card card-custom gutter-b elevation-4">
        <div class="card-header">
          <div class="card-title">
            <h1>
              Pedido {{ index + 1 }}
              <!-- <small>sub title</small> -->
            </h1>
          </div>
        </div>
        <div class="card-body">
          <div class="p-1" v-for="produto in item.produto" :key="produto.id">
            <h2>{{ produto.nome }}</h2>
            <h5>{{ produto.descricao }}</h5>
            <br />
          </div>
        </div>
        <button
          v-show="lista_permissoes_filial.u_FinanPedidosReceber"
          @click="feito(item.id)"
          class="btn btn-icon btn-light btn-lg mx-1"
          v-b-tooltip.hover
          title="Confirmar pedido"
        >
          <i class="far fa-check-circle fa-xl text-info "></i>
        </button>
      </div>

      <!-- <b-table
              :fields="['id','valor_total','total_pago', 'Forma_pagamento', 'acoes']"
              :items="lista_finan_pedido_recebimento"
              :per-page="perPage"
              :current-page="currentPage"
              id="pedidos_recebimento-table"
              class="table table-head-custom table-vertical-center table-head-bg table-borderless"
              show-empty
              empty-text="Nenhum registro encontrado!"
          >
            <template #head(acoes)><span></span></template>
            
            <template #cell(valor_total)="{item}">
              <div>
                {{ item.valor_total | formatMoney }}
              </div>
            </template>
            <template #cell(acoes)="{item}">
              <div class="text-right w-100">
                <button
                    v-show="lista_permissoes_filial.u_FinanPedidoRecebimento"
                    @click="verDetalhes(item)"
                    class="btn btn-icon btn-light btn-sm mx-1"
                    v-b-tooltip.hover title="Ver Detalhes"
                >
                  <i class="far fa-eye text-success"></i>
                </button>

                <button
                    v-show="lista_permissoes_filial.u_FinanPedidoRecebimento"
                    @click="atualizar(item)"
                    class="btn btn-icon btn-light btn-sm mx-1"
                    v-b-tooltip.hover title="Editar registro"
                >
                  <i class="far fa-edit text-primary"></i>
                </button>

              
              
                <button
                    @click="darBaixa(item)"
                    class="btn btn-icon btn-light btn-sm mx-1 text-warning"
                    v-b-tooltip.hover title="Dar Baixa"
                >
                  <i class="far fa-list-alt text-warning"></i>
                </button>
              </div>
            </template>
          </b-table>
          <b-pagination
              v-model="currentPage"
              :total-rows="lista_finan_pedido_recebimento.length"
              :per-page="perPage"
              aria-controls="pedidos_recebimento-table"
          >
          </b-pagination> -->
    </div>
  </div>
  <!-- </div>
  </div> -->
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import moneyFormat from "@/core/mixins/moneyFormat";

export default {
  mixins: [moneyFormat],
  data() {
    return {
      currentPage: 1,
      perPage: 10,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Pedidos" }]);
  },
  computed: {
    lista_finan_pedido_recebimento() {
      return this.$store.state.financeiro.lista_finan_pedido_recebimento;
    },
    mensagem_alert() {
      return this.$store.state.financeiro.mensagem_alert;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
  },
  created() {
    this.listar_finan_pedido_recebimento();
  },
  methods: {
    async listar_finan_pedido_recebimento() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store
        .dispatch("financeiro/listar_finan_pedido_recebimento_espera")
        .finally(() => {
          this.$store.dispatch("configEmpresa/MudarPreloader", false);
          this.$store.dispatch("configEmpresa/atualizar", "");
        });
    },
    verDetalhes(value) {
      this.$store.dispatch("configEmpresa/atualizar", value);
      this.$router.push({
        name: "showFinanPedidoRecebimento",
        params: { pedido_id: value.id },
      });
    },
    atualizar(value) {
      this.$store.dispatch("configEmpresa/atualizar", value);
      this.$router.push({ name: "createFinanPedidoRecebimento" });
    },
    feito(value) {
      this.$store.dispatch("financeiro/pedido_feitro", value);
      this.alertMessage(this.mensagem_alert.tipo, this.mensagem_alert.message);
    },
    async confirm(value) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação bloqueara essa moeda no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            this.bloquear(value);
          }
        },
      });
    },
    async bloquear(value) {
      await this.$store.dispatch(
        "financeiro/bloquear_finan_pedido_recebimento",
        value
      );
      this.alertMessage(this.mensagem_alert.tipo, this.mensagem_alert.message);
    },
    darBaixa(value) {
      value.status = 1;
      this.$store.dispatch("financeiro/finan_pedido_recebimento_feito", value);
      this.lista_finan_pedido_recebimento =
        this.lista_finan_pedido_recebimento.filter(
          (array) => array.id !== value.id
        );
    },
    async notafinalconfirm(value) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação gera uma nota fiscal do pedido  no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            this.gerarnotafinal(value);
          }
        },
      });
    },
    async gerarnotafinal(value) {
      await this.$store.dispatch(
        "financeiro/nota_fiscal_finan_nota_fiscal_saida",
        value
      );
      this.alertMessage(this.mensagem_alert.tipo, this.mensagem_alert.message);
    },

    alertMessage(tipo, mensagem) {
      this.$fire({
        title: "Aviso",
        text: mensagem,
        type: tipo,
        timer: 3000,
      }).then((r) => {
        console.log(r.value);
      });
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0px 5px 11px 0px rgba(0, 0, 0, 0.2);
  transition: 0.8s;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 6px 8px 17px 6px rgba(0, 0, 0, 0.2);
}
</style>